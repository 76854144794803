<template>
  <div>
    <Header />
    <div class="page-title">
      <div class="container">
        <div class="title text-black">Últimas notícias</div>
      </div>
    </div>

    <transition
      enter-active-class="animated fadeIn"
      leave-active-class="animated fadeOut"
    >
      <div v-show="false" class="bg-cover"></div>
    </transition>

    <section class="products pt-2-mobile pt-2-tablet">
      <div class="container">
        <div class="subtitle desktop-hidden tablet-hidden">Faça sua busca</div>
        <div class="search-holder desktop-hidden tablet-hidden">
          <input type="text" placeholder="Busca..." />
          <font-awesome-icon :icon="['fas', 'search']" />
        </div>
        <div v-show="loadingPosts" class="flex-center mt-3">
          <div class="loading2"></div>
        </div>
        <transition enter-active-class="animated fadeInUp">
          <div v-show="!loadingPosts" class="posts-holder mt-2-mobile">
            <PostCard
              v-for="post in posts"
              :key="post.id"
              :title="post.title"
              :text="post.summary"
              :image="post.image"
              :slug="post.slug"
              :date="post.startDate"
            />
          </div>
        </transition>
        <div class="right-bar">
          <div class="subtitle mobile-hidden">FAÇA SUA BUSCA</div>
          <div class="search-holder mobile-hidden">
            <input
              type="text"
              v-model="keyword"
              placeholder="Pesquise..."
              @keypress.enter="search"
            />
            <font-awesome-icon :icon="['fas', 'search']" @click="search" />
          </div>

          <div class="subtitle mt-2">ASSUNTOS DO BLOG</div>
          <ul class="cultures">
            <li
              @click="selectCategory(cat)"
              v-for="(cat, index) in categories"
              :key="index"
              :class="{ active: cat.isSelected }"
            >
              {{ cat.name }}
            </li>
          </ul>
        </div>
      </div>
    </section>
    <Footer />
  </div>
</template>
<script>
import http from "@/http";
import Header from "@/components/Header";
import Footer from "@/components/Footer";
import PostCard from "@/components/PostCard";
export default {
  name: "Blog",
  components: {
    Header,
    Footer,
    PostCard,
  },
  data() {
    return {
      posts: [],
      categories: [],
      selectedCategoryId: "",
      loadingPosts: false,
      loadingCategories: false,
      keyword: "",
    };
  },
  created() {
    this.getPosts();
    this.getCategories();
  },
  methods: {
    search() {
      this.getPosts();
    },
    selectCategory(cat) {
      if (cat.id === this.selectedCategoryId) this.selectedCategoryId = "";
      else this.selectedCategoryId = cat.id;

      this.categories.map((c) => {
        c.isSelected = c.id == this.selectedCategoryId ? true : false;
      });
      this.$forceUpdate();
      this.getPosts();
    },
    getPosts() {
      this.loadingPosts = true;
      http
        .get(
          `post/List?orderBy=startDate&take=-1&blogCategoryId=${this.selectedCategoryId}&keyword=${this.keyword}`
        )
        .then((res) => {
          this.posts = res.data.list;
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.loadingPosts = false;
        });
    },
    getCategories() {
      this.loadingCategories = true;
      http
        .get(`blogCategory/List?orderBy=-name&take=-1`)
        .then((res) => {
          this.categories = res.data.list;
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.loadingCategories = false;
        });
    },
  },
};
</script>
<style lang="scss" scoped>
section.products .container {
  display: grid;
  justify-content: space-between;
  align-items: flex-start;
  grid-template-columns: 0.8fr 0.2fr;
  gap: 0 3rem;
}

.posts-holder {
  position: relative;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 0.3333fr));
  gap: 25px 15px;
  .product {
    position: relative;
    overflow: hidden;
    &:hover {
      h1 {
        color: var(--primary);
      }
      .img-holder img {
        transform: translate3d(-50%, -50%, 0) scale(1.15);
      }
    }
    .img-holder {
      position: relative;
      width: 100%;
      padding-bottom: 100%;
      overflow: hidden;
      img {
        position: absolute;
        width: 100%;
        top: 50%;
        left: 50%;
        right: 0;
        margin: 0 auto;
        transform: translate3d(-50%, -50%, 0);
        transition: transform 0.7s ease;
        backface-visibility: hidden;
        image-rendering: -moz-crisp-edges; /* Firefox */
        image-rendering: -o-crisp-edges; /* Opera */
        image-rendering: -webkit-optimize-contrast; /* Webkit (non-standard naming) */
        image-rendering: crisp-edges;
        -ms-interpolation-mode: nearest-neighbor; /* IE (non-standard property) */
      }
    }
    h1 {
      font-size: 1.4em;
      text-align: center;
      margin-top: 10px;
      transition: color 0.25s ease;
    }
  }
}

.right-bar {
  position: relative;
}

.subtitle {
  font-family: fontBold;
  font-size: 1.2em;
  color: var(--primary);
  letter-spacing: 2px;
}

.search-holder {
  position: relative;
  width: 100%;
  height: 42px;
  border-bottom: 1px solid #666;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
  input {
    position: relative;
    height: 100%;
  }
  svg {
    cursor: pointer;
  }
}

.categories {
  position: relative;
  width: 100%;
  margin: 15px auto 0 auto;
  li {
    position: relative;
    font-size: 1.2em;
    margin-top: 15px;
    cursor: pointer;
  }
}

.cultures,
.nutrients {
  position: relative;
  width: 100%;
  margin: 15px auto 0 auto;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: 8px;
  align-items: center;
  li {
    position: relative;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 4px 10px;
    border: 1px solid #ccc;
    border-radius: 2px;
    cursor: pointer;
    transition: background-color 0.25s ease, border-color 0.25s ease,
      color 0.25s ease;
    &.active {
      border-color: var(--primary);
      background-color: var(--primary);
      color: #fff;
    }
    .remove {
      position: relative;
    }
  }
}

@media screen and(max-width: 720px) {
  section.products .container {
    display: block;
  }
  .posts-holder {
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 25px 12px;
  }

  button.btn.border.icon.filter {
    border-width: 1px;
    width: 100%;
  }
}

@media only screen and (min-width: 721px) and (max-width: 1159px) {
  section.products .container {
    grid-template-columns: 1fr 0.2fr;
    gap: 0 1.5rem;
  }
  .posts-holder {
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 25px 12px;
  }
}
</style>
